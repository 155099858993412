.blogCard {
    width: 100%;
    background: #c8dec6;
    border-radius: 10px;
    cursor: pointer;
    max-width: 300px;
    box-shadow: 0px 4px 4px #c8dec6;
}
.blogCardImage {
    width: 100%;
    object-fit: cover;
    height: 250px;
    border-radius: 10px 10px 0 0;
}
.blogCardContent {
    padding: 1rem;
}
.blogCardContent h3 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--primary-color);
    margin: 0;
}

.blogCardContent p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--grenish-color);
    margin: 0;
}
.blogCardAuthor {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 2rem;
}

.blogCardAuthorImage {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}

.blogCardAuthorName h5 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--primary-color);
    margin: 0;
}

.blogCardAuthorName p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    color: var(--grenish-color);
    margin: 0;
}
.placeholderDiv {
    width: 100%;
    object-fit: cover;
    height: 250px;
    border-radius: 10px 10px 0 0;
    background-color: #a1b39f;
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.blogCardImageContainer{
    position: relative;
    width: 100%;
}
.blogCardButtons{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1rem;
    display: flex;
    gap: 0.5rem;
}
.blogCardButtonEdit{
    all: unset;
    padding: 0.5rem;
    border-radius: 4px;
    background-color: transparent;
    color: #ffffff;
    cursor: pointer;
    border: 1px solid #2F89EC;
    background-color: #2F89EC;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.blogCardButtonDelete{
    all: unset;
    padding: 0.5rem;
    border-radius: 4px;
    background-color: transparent;
    color: #ffffff;
    cursor: pointer;
    border: 1px solid #ec4b2f;
    background-color: #ec4b2f;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}