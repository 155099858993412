.button {
    all: unset;
    padding: 0.5rem;
    border-radius: 4px;
    background-color: transparent;
    color: #46A634;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid #46A634;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.activeButton {
    background-color: #46A634;
    color: white;
    border: 1px solid #46A634;
}

.button:hover {
    background-color: rgba(70, 166, 52, 0.4);
}
.button:active {
    background-color: transparent;
}
.button img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.buttonBox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    max-width: 1000px;
    margin: 0 auto;
    padding: 1rem;
}

.blogsContainer {
    max-width: 1400px;
    margin: 0 auto;
    padding-block: 5rem;
    /* display: grid; */
    /* grid-template-columns: repeat(3, 1fr); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5rem;
    /* place-items: center; */
    padding: 1rem;
    padding-bottom: 5rem;
}

@media screen and (max-width: 1400px) {
    .blogsContainer {
        grid-template-columns: repeat(2, 1fr);
        max-width: 1000px;
    }
}
@media screen and (max-width: 640px) {
    .blogsContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media screen and (max-width: 560px) {
    .buttonBox {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 410px) {
    .buttonBox {
        grid-template-columns: repeat(1, 1fr);
    }
}
