.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.card {
  width: 100%;
  margin: 10px;
  margin-bottom: 20px;
}
.cardInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  flex-wrap: wrap;
}
.blogCardButtonEdit {
  all: unset;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #2f89ec;
  background-color: #2f89ec;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
  margin-right: 5px;
}
.blogCardButtonDelete {
  all: unset;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #ec4b2f;
  background-color: #ec4b2f;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.form{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 30px;
}
.form button{
    margin-top: 10px;
    align-self: flex-end;
}

@media (min-width: 600px) {
  .card {
    width: 48%; /* Adjust as needed */
  }
}

@media (min-width: 960px) {
  .card {
    width: 32%; /* Adjust as needed */
  }
}
